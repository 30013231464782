import React, { useEffect } from "react";
import { Row, Col, Alert } from "reactstrap";

import { MdPrint } from "react-icons/md";
import BEMHelper from "react-bem-helper";

// Elements
import { Button } from "bokamera-embedded-ui";
import Panel from "@/components/elements/Panel";
import Spinner from "@/components/elements/Spinner";
import { scrollToElement, tryParseJSONObject } from "@/misc/common";

import "./Completed.css";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "@/hooks";
import Company from "../partials/Company";
import ArticleSummary from "../partials/ArticleSummary";
import ErrorAlert from "../partials/ErrorAlert";
import readConfigurationProperty from "@/misc/readConfigurationProperty";
import { persistor } from "@/store";

const c = new BEMHelper({ name: "Completed" });

export const Completed: React.FC = (props) => {
  const history = useHistory();
  const {
    checkout,
    selected: selectedArticle,
    entity: completedArticle,
  } = useAppSelector((s) => s.article);
  const company = useAppSelector((s) => s.company);
  const dispatch = useAppDispatch();

  const payWithPaysonV1 =
    company.data?.BookingSettings?.PaymentProviderId === 1;
  const payWithPaysonV2 =
    company.data?.BookingSettings?.PaymentProviderId === 2;
  const payWithBillmate =
    company.data?.BookingSettings?.PaymentProviderId === 3;
  const paymentIsEmbedded = payWithPaysonV2 || payWithBillmate;
  // const needToPay = paymentEnabled && statusRequiresPayment;

  useEffect(() => {
    window.onbeforeunload = () => {
      persistor.pause();
      dispatch({ type: 'PURGE_PERSISTED' });
    }
    window.onmessage = (ev) => {
      try {
        const data = tryParseJSONObject(ev.data);
        if (
          data &&
          data.event === "purchase_initialized" &&
          checkout?.entity?.Url
        ) {
          const billmateContainer: HTMLIFrameElement | null =
            document.querySelector(`[src^="${checkout.entity.Url}"]`);
          billmateContainer?.contentWindow?.postMessage(
            "purchase_complete",
            readConfigurationProperty("targetOrigin") ||
              readConfigurationProperty("_targetOrigin")
          );
        }
      } catch (error) {
        // intentionally left blank
      }
    };

    if (!!checkout.entity?.Snippet && paymentIsEmbedded) {
      if (!document.getElementById("paysonContainer")) {
        const div = document.createElement("div");
        div.innerHTML = checkout.entity.Snippet;
        window?.bookingAppContainer
        ?.getRootNode()
          // @ts-ignore
          ?.host?.parentNode?.prepend(div);
      }

      const intervalId = setInterval(() => {
        const paysonContainer =
          window?.bookingAppContainer?.querySelector("#paysonContainer") ||
          document.getElementById("paysonContainer");
        if (paysonContainer && company.data) {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
              navigator.userAgent
            )
          ) {
            scrollToElement({ company: company.data });
          }
          const scriptParentNode: Node | null = paysonContainer.parentNode;
          if (!!scriptParentNode) {
            // How to rewrite it in correct way?
            const scriptNode =
            // @ts-ignore
              scriptParentNode.getElementsByTagName("script")[0];
            const scriptNodeWithContent = document.createElement("script");
            scriptNodeWithContent.src = scriptNode.src;
            document.head.appendChild(scriptNodeWithContent);
            clearInterval(intervalId);
          }
        }
      }, 1000);
    }
  }, [checkout.entity, payWithPaysonV2, payWithBillmate]);

  if (!selectedArticle || !company.data) return null;

  if (checkout.error) {
    return (
      <Panel>
        {checkout?.error ? <ErrorAlert error={checkout.error} /> : null}
        <Row>
          <Col xs={12}>
            <Trans id="completed.error" />
          </Col>
        </Row>
      </Panel>
    );
  }

  return (
    <Panel>
      <div id="section-to-print">
        <h4
          className="text-left text-danger"
          style={{ marginBottom: 20, padding: "0.3rem" }}
        >
          <Trans id="youNeedToPayTheArticle"></Trans>
        </h4>

        {checkout.entity && payWithPaysonV1 ? (
          <Alert color="warning" className="clearfix">
            <div>
              <p>
                <Trans id="instructionsHasBeenSentToEmail"></Trans>
              </p>
            </div>
            <a
              className="float-right hidden-print"
              href={checkout.entity?.Snippet}
              target="_blank"
            >
              <Button>
                <Trans id="payNow"></Trans>
              </Button>
            </a>
          </Alert>
        ) : null}
        {company?.data.BookingSettings?.BookingReceiptMessage && (
          <p style={{ marginBottom: 20, padding: "0.3rem" }}>
            {company.data.BookingSettings.BookingReceiptMessage}
          </p>
        )}
        {checkout.isLoading ? (
          <div className="hidden-print">
            <div>
              <Spinner {...c("paymentLoading")} />
            </div>
            <Alert color="warning" className="clearfix">
              <Trans id="yourArticleMustBePaid"></Trans>
            </Alert>
          </div>
        ) : null}
        {checkout.entity?.Snippet ? (
          <Row className="hidden-print" style={{ marginBottom: "1rem" }}>
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{ __html: checkout.entity.Snippet }}
              />
            </Col>
          </Row>
        ) : checkout.entity?.Url ? (
          <iframe
            src={`${checkout.entity.Url}?activateJsEvents=1`}
            data-testid="billmateContainer"
            width="100%"
            height="900px"
            frameBorder="0"
          ></iframe>
        ) : null}
        {completedArticle ? (
          <div>
            <Row {...c("body")}>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Company />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <ArticleSummary article={selectedArticle} />
              </Col>
            </Row>
            <Row className="hidden-print">
              <Col xs={12}>
                <Button
                  className="button float-right"
                  onClick={() => {
                    window.print();
                  }}
                >
                  <MdPrint /> <Trans id="print"></Trans>
                </Button>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    </Panel>
  );
};

export default Completed;
