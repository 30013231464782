import React, { ReactNode, createRef } from "react";
import { compose, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { onSubmitActions } from "redux-form-submit-saga";
import { reduxForm, InjectedFormProps, FormErrors, getFormValues } from "redux-form";
import { Alert, Spinner, Card, Button as BootstrapButton, Input as BootstrapInput, FormGroup, Label, Row, Col } from "reactstrap";
import { getFormSubmitErrors, Field } from "redux-form";
import BEMHelper from "react-bem-helper";

// Partials
import Agreement from "@/components/partials/Agreement";
import Summary from "@/components/partials/Summary";
import PromoCode from "@/components/partials/PromoCode";
import SelectQuantities from "@/components/partials/SelectQuantities";
import CustomFields from "@/components/partials/CustomFields";
import bmApi from "@/services/bmApi";

// Forms
import CustomerForm from "@/components/forms/CustomerForm";
import LoginForm from "@/components/forms/LoginForm";
import CreateAccountForm from "../forms/CreateAccountForm";

// Components
import { Button } from "bokamera-embedded-ui";
import Loading from "@/components/elements/Spinner";
import Panel from "@/components/elements/Panel";

import { ConfigKeys } from "@/misc/constants";

import { Trans } from "@lingui/macro";
import * as actions from "@/actions";
import { RouteComponentProps, withRouter } from "react-router";
import { reset } from "redux-form";
import { withI18n, withI18nProps } from "@lingui/react";

import "./Confirm.css";
import { ApplicationState } from "@/store";
import Input from "../elements/Input";
import CustomerPromoCodes from "../partials/CustomerPromoCodes";
import { scrollToElement } from "@/misc/common";
import readConfigurationProperty from "@/misc/readConfigurationProperty";


const c = new BEMHelper("Confirm");

enum TabIds {
  CONTACT_INFORMATION_BOOKING_TAB_ID = "0",
  LOGIN_BOOKING_TAB_ID = "1",
  CREATE_ACCOUNT_TAB_ID = "2",
}

interface State {
  activeId: string | number;
  hideBookingButtons: boolean;
}
interface Props extends ReduxProps, RouteComponentProps, withI18nProps {}

export function withBookingRequirements(WrappedComponent: React.ComponentType) {
  const ComponentWithUser: React.FC<ReduxProps> = (props) => {

    if(props.authentication.isLoggedIn && !props.user?.CustomerProfile) {
      return <Loading />;
    }

    return <WrappedComponent {...props} />;
  };

  return ComponentWithUser;
}

export class Confirm extends React.Component<Props & InjectedFormProps, State> {
  loginRef = createRef<any>();
  state = { activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID, hideBookingButtons: false  };

  bookingButtonsContainerRef = createRef<any>();
  
  componentDidMount() {
    scrollToElement({
      element: this.loginRef.current,
      scrollOffsetY: Number(readConfigurationProperty("topOffset", 0)) + 72,
    });
  }

  render() {
    const { user, authentication, booking, company, configuration, dispatch } =
      this.props;

    const useContactInformationBooking =
      configuration.bookMethods.indexOf(
        ConfigKeys.BOOK_METODS_CONTACT_INFORMATION
      ) > -1;
    const useLoginBooking =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_LOGIN) > -1;
    const useCreateAccount =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_CREATE_ACCOUNT) >
      -1;
    const useShowRebateCodeField = configuration.showRebateCodeField;

    let tabs = [];
    
    if (useContactInformationBooking) {
      tabs.push({
        id: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
        title: <Trans id="book" />,
        content: this.renderBookActions(),
      });
    }
    if (useLoginBooking) {
      tabs.push({
        id: TabIds.LOGIN_BOOKING_TAB_ID,
        title: <Trans id="logIn"></Trans>,
        content: this.renderLogin(),
      });
    }

    if (useCreateAccount) {
      tabs.push({
        id: TabIds.CREATE_ACCOUNT_TAB_ID,
        title: <Trans id="createAccount"></Trans>,
        content: this.renderCreateAccount(),
      });
    }

    if (!booking.service) return null;

    return (
      <div ref={this.loginRef} {...c()}>
        <div {...c("row")}>
          <div {...c("mainCol")}>
            <Panel>
              <SelectQuantities />
              {useShowRebateCodeField !== false &&
              company?.WidgetSettings?.ShowRebateCodeField ? (
                <PromoCode />
              ) : null}
              {authentication.isLoggedIn ? <CustomerPromoCodes /> : null}
            </Panel>
            {booking.customFields && booking.customFields.length > 0 && (
              <Panel key="customFields">
                <CustomFields
                  customFields={booking.customFields}
                  prefix="id-"
                />
              </Panel>
            )}
            {authentication.isLoading && (
              <Loading>
                <Trans id="authenticationLoading"></Trans>
              </Loading>
            )}
            {!authentication.isLoading &&
            authentication.isLoggedIn &&
            user?.CustomerProfile?.Email ? (
              <div>
                <Panel title={<Trans id="confirm.customerFormTitle" />}>
                  <CustomerForm />
                  <Alert color="info">
                    <p>
                      <Trans id="youAreLoggedInAs"></Trans>{" "}
                      {user.CustomerProfile.Email}.
                    </p>
                    <Button
                      block
                      onClick={() => {
                        dispatch(actions.logout());
                      }}
                    >
                      <Trans id="logOut"></Trans>
                    </Button>
                  </Alert>
                </Panel>
              </div>
            ) : null}
            {!authentication.isLoading && !authentication.isLoggedIn ? (
              <>
                {this.state.activeId ===
                TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID
                  ? this.renderBookActions()
                  : null}

                {this.state.activeId === TabIds.CREATE_ACCOUNT_TAB_ID
                  ? this.renderCreateAccount()
                  : null}
              </>
            ) : null}
          </div>
          <div {...c("summaryCol")}>
            <Card>
              <div {...c("summaryBody")}>
                <Summary service={booking.service} />
              </div>
              <div {...c("summaryFooter")}>{this.renderBook()}</div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  renderCreateAccount = () => {
    // @ts-ignore
    const { createAccount, dispatch } = this.props;
    const isSuccess =
      !createAccount.error && createAccount.data?.ResponseStatus ? true : false;

    return (
      <Panel style={{ marginTop: -2 }}>
        <CreateAccountForm />
        {!isSuccess ? (
          <Button
            {...c("cancel")}
            outline
            block
            onClick={() => {
              dispatch(reset("createAccountForm"));
              this.setState({
                activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
              });
            }}
          >
            <Trans id="cancel" />
          </Button>
        ) : (
          <Button
            block
            onClick={() => {
              dispatch(reset("createAccountForm"));
              this.setState({
                activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
              });
            }}
          >
            <Trans id="confirm.goBackToBooking" />
          </Button>
        )}
      </Panel>
    );
  };

  renderBookActions = () => {
    const { configuration, dispatch, prices, i18n } = this.props;

    const useContactInformationBooking =
      configuration.bookMethods.indexOf(
        ConfigKeys.BOOK_METODS_CONTACT_INFORMATION
      ) > -1;

    const useLoginBooking =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_LOGIN) > -1;
    const useCreateAccount =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_CREATE_ACCOUNT) >
      -1;

    return (
      <React.Fragment>
        {useContactInformationBooking ? (
          <Panel style={{ marginTop: -2 }}>
            <CustomerForm anonymous />
          </Panel>
        ) : null}

        {useLoginBooking || useCreateAccount ? (
          <Panel>
            <div {...c("quickActions")}>
              {useLoginBooking ? (
                <React.Fragment>
                  <Button
                    block
                    disabled={prices.isLoading || !prices.data}
                    onClick={(evt) => {
                      evt.preventDefault();
                      dispatch(actions.login());
                    }}
                  >
                    <Trans id="logIn"></Trans>
                  </Button>
                </React.Fragment>
              ) : null}
              {useLoginBooking && useCreateAccount ? (
                <div {...c("orText m-2")}>
                  <Trans id="common.or"></Trans>
                </div>
              ) : null}
              {useCreateAccount ? (
                <Button
                  block
                  onClick={() => {
                    this.setState({ activeId: TabIds.CREATE_ACCOUNT_TAB_ID });
                  }}
                >
                  <Trans id="createAccount"></Trans>
                </Button>
              ) : null}
            </div>
            {useLoginBooking && (
              <Alert color="info">
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n._("doYouHaveAccount"),
                  }}
                />
              </Alert>
            )}
          </Panel>
        ) : null}
      </React.Fragment>
    );
  };

  sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  renderLogin = () => {
    return (
      <Panel style={{ marginTop: -2 }}>
        <LoginForm
          handleSubmit={() => {
            // After login is done, switch active tab to 0
            this.setState({
              activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
            });
          }}
        />
      </Panel>
    );
  };

  renderBook = () => {
    const {
      error,
      handleSubmit,
      submitErrors,
      booking,
      change,
      bookButtonsBottom,
      configuration
    } = this.props;

    const paymentEnabled = booking?.service?.IsPaymentEnabled;
    let bookTheTime = <Trans id="bookTheTime"></Trans>;

    const isEnableCustomerManualPayment = booking?.service?.EnableCustomerManualPayment;

    if (paymentEnabled) {
      bookTheTime = <Trans id="bookAndPayTheTime"></Trans>;
    }

    if(booking.time?.Free === 0 && booking.service?.EnableBookingQueue) {
      bookTheTime = <Trans id="addToQueue" />;
    }

    const hasErrors = submitErrors && Object.keys(submitErrors).length > 0;

    const bookingsContainerHeight = this.bookingButtonsContainerRef.current
      ? this.bookingButtonsContainerRef.current.getBoundingClientRect().height
      : 0;

    const isTouchingBottom = bookButtonsBottom - 40 > 0;

    return (
      <>
        <div style={{ position: "absolute", zIndex: -1 }}>
          <Field name="PaymentOption" type="hidden" component={Input} />
        </div>
        <form {...c("footer")} onSubmit={handleSubmit}>
          {error && (
            <Alert color="danger">
              {error}
              {submitErrors && (
                <ul>
                  {Object.keys(submitErrors).map((key) => (
                    // @ts-ignore
                    <li key={key}>{submitErrors[key]}</li>
                  ))}
                </ul>
              )}
            </Alert>
          )}

          <div {...c("agreement")}>
            <Agreement />
          </div>
          <div
            {...c("placeholder")}
            style={{
              height:
                isTouchingBottom && this.bookingButtonsContainerRef?.current
                  ? `${
                      this.bookingButtonsContainerRef.current.getBoundingClientRect()
                        .height
                    }px`
                  : "0",
            }}
          ></div>
          {/* TODO: set max scroll up */}
          <div
            ref={this.bookingButtonsContainerRef}
            {...c("bookButtons", isTouchingBottom ? "fixed" : "relative")}
            style={{
              bottom: isTouchingBottom
                ? bookButtonsBottom + 34
                : 0 + bookingsContainerHeight,
            }}
          >
            <Button
              {...c("submitButton")}
              primary
              type="submit"
              disabled={booking.isLoading}
              onClick={() => {
                change("PaymentOption", "");
                setTimeout(() => {
                  const scrollTo = bookButtonsBottom;
                  if (isTouchingBottom) {
                    window.parentIFrame.scrollToOffset(
                      0,
                      scrollTo + configuration.topOffset!
                    );
                  }
                }, 300);
              }}
            >
              {bookTheTime}
            </Button>
            {isEnableCustomerManualPayment ? (
              <>
                <div {...c("orText m-2")}>
                  <Trans id="common.or"></Trans>
                </div>
                <Button
                  {...c("submitButton")}
                  type="submit"
                  disabled={booking.isLoading}
                  onClick={() => {
                    change("PaymentOption", 3);
                  }}
                >
                  <Trans id="payOnSite" />
                </Button>
              </>
            ) : null}

            
              <Row className="mt-2">
                <Col {...c('subscribeColumn')}>
                  <Field
                    style={{
                      position: "relative",
                      marginLeft: 0,
                      marginTop: 0,
                    }}
                    id="_subscribe"
                    className="form-component"
                    name="_subscribe"
                    type="checkbox"
                    component={Input}
                  />
                  <Label for="_subscribe">
                    <Trans id="Confirm.subscribe" />
                  </Label>
                </Col>
              </Row>
            

            {booking.isLoading && !hasErrors ? (
              <div {...c("loader", "absolute", `mt-1 text-center`)}>
                <Spinner />
              </div>
            ) : null}
          </div>
        </form>
      </>
    );
  };
}

function mapStateToProps(state: ApplicationState, props: any) {
  return {
    initialValues: {
      service: { Id: props.match.params.serviceId },
      _subscribe: !!state.user.data?.CompanyCustomers?.find(
        (companyCustomer) =>
          companyCustomer.CompanyId === state.company.data?.Id &&
          companyCustomer.SubscribedToNewsletter
      ),
    },
    authentication: state.authenticate,
    services: state.services || [],
    booking: state.booking,
    configuration: state.configuration.data,
    company: state.company.data,
    prices: state.prices,
    submitErrors: getFormSubmitErrors("bookForm")(state),
    createAccount: state.createAccount,
    user: state.user?.data,
    bookButtonsBottom: state.ui.parentScrollTop,
  };
}

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  connector,
  withBookingRequirements,
  withI18n(),
  reduxForm({
    form: "bookForm",
    onSubmit: onSubmitActions("BOOK", "SAVE_BOOKING", "FORM_ERROR"),
  })
)(Confirm) as React.ComponentType;
